/* You can add global styles to this file, and also import other style files */


@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

//imports the brand specific theme, see more in angular.json
@import 'theme';

//for testing
//$bg-color: #212529;
//$text-color: #dee2e6;
//$text-color: red;
//$bg-color: #483d8b; //purple
//$bg-color: #003153; //blue
//$bg-color: yellow;
//$primary: magenta;


//hamburger-menu in text-color
$navbar-dark-icon-color: $text-color;

$form-invalid-color-dark: $danger;
$form-invalid-border-color-dark: $danger;

//for xmas page
$carousel-dark-indicator-active-bg:  white;
$carousel-dark-caption-color:       white;
$carousel-dark-control-icon-filter:  unset;



@import 'bootstrap/scss/bootstrap';

//@import url('https://fonts.googleapis.com/css2?family=Asul:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); //for landings
@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap'); //for xmas or landings
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap'); //for slotvibe
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); //for tradition



:root {
  --bs-body-bg: #{ $bg-color } !important;
  --bs-body-color: #{ $text-color } !important;
  --bs-box-shadow: 0 4px 6px -1px rgba(27, 23, 23, .2), 0 2px 4px -1px rgba(0, 0, 0, .12) !important;
  //--bs-emphasis-color: #{ $text-color } !important;
  //--bs-emphasis-color-rgb: rgb(#{ $text-color }) !important;
  /* And any other bs-dark-* variables you want to override */

}



.bg-main {
  background-color: #{ $bg-color };
}
.bg-darker {
  background-color: mix($bg-color, #000, 80%);
}
.bg-darkest {
  background-color: mix($bg-color, #000, 70%) ;
}

.bg-lighter {
  background-color: mix($bg-color, #fff, 80%);
}

.text-darker {
  color: mix($text-color, #000, 80%);
}

//hamburger no border
.navbar-toggler {
  border: none !important;
  &:focus{
    color: transparent !important;
  }
}


#rivalHeaderNavAndLogoSection {
  width: 50%;
  text-align: center;
  @media (min-width: 576px) {
    width: auto;
    text-align: start;
  }
  img {
    width: 100%;
    max-width: 150px;
  }
}


////have a better looking hamburger menu and icon in sidebar - on our brands
//header {
//  .navbar-brand img {
//    max-height: 70px;
//    margin: -8px 0;
//    max-width: 230px !important;
//  }
//}
//.logo-and-nav{
//  @media (min-width: 768px) {
//    visibility: hidden;
//    button {
//      display: none;
//    }
//    .navbar-brand img {
//      height: 40px;
//    }
//  }
//}
//
//.logo-and-nav-in-sidebar {
//  display: none;
//  @media (min-width: 768px) {
//    display: block;
//    .navbar-brand {
//      display: block;
//      text-align: center;
//
//      img {
//        max-width: 220px;
//      }
//    }
//
//    .close-btn {
//      text-align: right;
//      svg {
//        width: 1.5rem;
//        fill: $text-color;
//        opacity:0.5;
//        &:hover {
//          opacity: 1;
//        }
//      }
//    }
//  }
//
//}
//
//#sidebar-wrapper {
//  &.sticky-sidebar {
//    top: 0;
//    margin-top: 110px;
//
//    @media (min-width: 576px) {
//      margin-top: 66px;
//    }
//
//    @media (min-width: 768px) {
//      margin-top: 0;
//      z-index: 2000;
//      height: 100vh;
//    }
//
//    &.with-token {
//      margin-top: 48px;
//      @media (min-width: 576px) {
//        margin-top: 66px;
//      }
//      @media (min-width: 768px) {
//        margin-top: 0;
//      }
//    }
//  }
//
//}
//
//#main-wrapper {
//  margin-top: 120px;
//
//  &.with-token {
//    margin-top: 76px;
//    @media (min-width: 768px) {
//      &.sticky-main {
//        margin-top: 50px;
//      }
//    }
//  }
//
//  @media (min-width: 576px) {
//    margin-top: 76px;
//  }
//
//}



///////////////////////////////////////////////////////////////////////////////////////////////////////////

//carousel
.car-item-wrapper {
  display: flex;
  background-color: $bg-color;
}

.car-item-xs {
  border: 0;
  margin: 0.25rem;
  width: calc(100%/3);
  img {
    height: 50px;
    width: 80%;
  }
}
.car-item-sm {
  border: 0;
  margin: 0.25rem;
  width: calc(100%/4);
  img {
    height: 40px;
    width: 80%;
  }
}
.car-item-md {
  border: 0;
  margin: 0.25rem;
  width: calc(100%/5);
  img {
    height: 40px;
    width: 80%;
  }
}
.car-item-lg {
  border: 0;
  margin: 0.25rem;
  width: calc(100%/6);
  img {
    height: 50px;
    width: 80%;
  }
}

.half-div {
  position: relative;
  overflow: hidden; // This ensures that only half of your div is visible
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  opacity: 0.5;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%; // Covers the right half of the div
    background: $bg-color;
  }
  &::after {
    content: "";
    position: absolute;
    width: 50%; // Only covers the left half of the div
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, transparent, $bg-color); // Fades to white from left to center.
  }
}
.default-cursor {
  //for hidden items
  cursor: default !important;
}

.car-title {
  color: $text-color !important;
  .casino-icon {
    opacity: 0.25;
  }
  &:hover {
    .casino-icon {
      opacity: 1;
    }
  }
}

//owl-carousel version (HOME promo slides)
#promos {
  a {
    color: $text-color !important;
    text-decoration: none;
    font-weight: bold;
    opacity: 0.8;
    &:hover {
      opacity: 1
    }
  }
  .owl-stage {
    display: flex;
  }

}




//to have hover effect on images
.clickable {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  will-change: transform; /* Hint browser to optimize for transform */

}
.clickable:hover {
  transform: translateY(-0.25rem);
}

//on our/not Rival brand: show disabled games in gray
.disabled-game {
  opacity: 50%;
  filter: grayscale(1);
}

//badges on images
.img-container {
  position: relative;
  display: inline-block; // So the container doesn't take up the whole line
  height: 100%;
  width: 100%;
  padding-top: 100%;
  //padding-top: calc(100% - var(--bs-gutter-y));
  overflow:hidden;

  img {
    //height: 100%; //to fix smaller instanet images on bigger screens
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: auto 1/1;
  }


  .game-label {
    position: absolute;
    top: 5%;
    right: 5%;
    white-space: normal;

    visibility: hidden; // Initially hides the badge
    opacity: 0;
    z-index: 2;
    transition: visibility 0s linear 0.3s, opacity 0.3s linear; // This ensures the badge is not visible initially and becomes visible gradually when hovered.
  }

  &:hover .game-label {
    visibility: visible; // Shows the badge on hover
    opacity: 1;
    transition-delay: 0s; // This makes sure the badge shows immediately when you hover
  }
}

//BETA ribbon on images
.ribbon {
  position: absolute;
  top: 7%;
  right: -45%;
  background: $info;
  color: #fff;
  padding: 1% 50%;
  font-weight: bold;
  transform: rotate(45deg);
  z-index: 1;
  text-transform: uppercase;
  text-align: center;
}
//in carousels
.image-with-ribbon {
  position: relative;
  width: 100%;
  height:100%;
  padding-top: 100%;
  overflow: hidden;
  display:inline-block;

  img {
    position: absolute;
    top:0;
  }
}


//game-details
.play-game-image-container {
  img {
    @media (max-width: 576px) {
      max-width: 150px;
    }
  }
  .overlay {
    img {
      width: 50%;
      height: 50%;
    }
    cursor: pointer;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    transition: opacity .5s;
    will-change: opacity;
    z-index: 2;

    &:hover {
      opacity: 1;
    }

    @media (hover:none) {
      opacity: 1;
      z-index: auto;
    }
  }
}

.jackpot-overlay {
  position:static;
  @media (min-width: 576px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.colored-icon {
  width: 3rem;
  path {
    fill: $primary !important;
  }
}

//on iframe bottom bar - not used Todo: delete
.bottom-bar{
  bottom: 0;
  height: 50px;
  background-color: mix($bg-color, #000, 70%) !important;
}

.game-iframe-bar {
  height: 40px;

  //this version is not working on mobile (covers too much from the game itself)
  //position: relative;
  //z-index: 1 !important;

  img {
    max-width: 100px;
    @media (min-width: 576px) {
      max-height: 40px;
    }
  }

  a {
    display: flex;
    align-items: center;
    margin: 0 0.5rem;

    svg {
      width: 1.5rem;
      path {
        fill: $text-color !important;
        opacity: 0.5;
      }
    }

    &:hover {
      path {
        opacity:1;
        fill: $primary !important;

      }
    }
  }
}




#leaderboard {
  input {
    background-color: var(--bs-card-cap-bg);
    border-color: $secondary;
  }
  table {
    --bs-table-bg: mix($bg-color, #000, 70%);
    th, td:first-child {
      color: mix($text-color, #000, 70%)
    }
  }
}




//itemsShowCase component buttons: remove hover on tap devices
#showcaseButtons {
  @media (hover: none) {
    .btn:hover {
      background-color: initial !important;
      color: var(--bs-btn-color) !important;
    }
  }
}


//************************************************************
//sidebar from large screens, full screen on smaller screens
.sticky-sidebar {
  .nav-link {
    --bs-nav-link-color: $text-color !important;
    .casino-icon {
      opacity: 0.5;
    }
    &:hover {
      .casino-icon {
        opacity: 1;
      }
    }
  }
  .active-link {
    .casino-icon {
      opacity: 1;
    }
  }

  position: fixed;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  //header nav is 86 px in small screens, 56px on larger
  top: 86px;
  height: calc(100vh - 86px);

  padding-bottom: 60px;

  @media (min-width: 576px) {
    top: 56px;
    height: calc(100vh - 56px);

    //padding-bottom: 30px;
  }
  @media (min-width: 768px) {
    width: 240px;
  }

  &.with-token {
    top: 56px;
    height: calc(100vh - 56px);
  }
}

.sticky-main {
  display: none;
  width: 100%;
  @media (min-width: 768px) {
    display: block;
    position: absolute;
    left: 240px;
    width: calc(100% - 240px);
  }
}
.pushed-down {
  //to be under the fixed header nav (78 px in small screens, 56px on larger) if we are not signed in and have login buttons
  margin-top: 96px !important;

  @media (min-width: 576px) {
    margin-top: 56px !important;
  }
}



//languages selector
.dropdown-bg {
  background-color: mix($bg-color, #000, 90%) !important;
  label {
    color: $text-color !important;
    cursor: pointer;
  }

  .form-check-input:checked {
    //background-color: $text-color;
    background-color: transparent;
    border-color: $text-color;

    //the point inside the checkbox when checked
    $form-check-input: str-slice(ie-hex-str($text-color), 4); // Remove '#', ie-hex-str returns a color as ie-compatible hex string
    &[type=radio] {
      --bs-form-check-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="2" fill="%23#{$form-check-input}"/></svg>');
    }
  }
  .form-check-input:focus {
    border-color: $text-color;
    box-shadow: 0 0 0 0.15rem rgba($text-color, 0.25) !important;
  }

  .dropdown-item:hover {
    .form-check-input {
      border-color: $text-color;
    }
  }
  .dropdown-item:active {
    background-color: $text-color;
  }
}

.casino-icon {
  width: 1.5rem;
  margin-right: 0.5rem;
  path {
    fill: $text-color !important;
  }
}

//***** chat *****
.app-chat {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 3000;
  input {
    z-index: 3001;
  }
  img {
    width: 1.5rem;
    opacity: 0.5;
  }
  &:hover {
    img {opacity:1};
    cursor:pointer;
  }
  @media (hover: none) {
    img {
      opacity:1
    }
  }
}

.live_chat {
  border-radius: 1.25rem;
  width: 90vw;
  max-width: 300px;
  height: 50vh;
  max-height: 500px;
  background-color: #e7e7e7;
  overflow: hidden;
}

.chat-closer {
  cursor: pointer;
  svg {
    width: 1rem;
  }
}

.chat-user-message {
  text-align: start;
  background-color: #00ff002a;
  margin-left: 0.125rem !important;
  float: left;
  color: #000000;

}
.chat-service-message {
  text-align: end;
  background-color: #00c3ff34;
  margin-right: 0.125rem !important;
  float: right;
  color: #000000;
}

.chat-message-name {
  font-size: 0.8125rem;
  font-weight: 600;
}
.chat-message-text {
  font-size: 0.875rem;
}

.input-section > input {
  border: 1px solid #e7e7e7 !important;
  color: #000000 !important;
  background-color: #fff !important;
}



//reg modal terms scrollbox
.terms-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.terms-scrollbox {
  height: 300px;
  overflow-y: auto;
}


//legal section navigation
#info-tab, .nav-underline {
  gap: 0;
  .nav-link {
    color: $text-color;
    font-weight: 700;
    text-align: start;
    padding-left: 1rem;
    border: 0.125rem solid transparent !important;

    &:hover, &:focus {
      border-bottom: none;
      //background-color: mix($bg-color, #fff, 95%) !important;
      background-color: rgba($text-color, 0.25) !important;
    }
  }
  .nav-link.active {
    border-left: 0.125rem solid $primary !important;
    border-bottom: none;
    background-color:  mix($bg-color, #fff, 95%) !important;
  }

}
#legalNavDropdownBtn {
  button {
    background-color:  mix($bg-color, #000, 80%) !important;
    color: $text-color;
    font-weight: bold;
    border: transparent;
  }
  .dropdown-menu {
    background-color: mix($bg-color, #000, 80%) !important;
  }
  .dropdown-item.active {
    border-left: 0.125rem solid $primary !important;
    border-bottom: none;
    background-color:  mix($bg-color, #fff, 95%) !important;
  }

}

.sort-by-dropdown {
  .dropdown-item:hover {
    background-color: rgba($text-color, 0.25) !important;
  }
}




//**********************************************************
.yourclass.toast-success {
  background-color: $success;
}
.yourclass.toast-error {
  background-color: $danger;
}
.yourclass.toast-info {
  background-color: $info;
  //background-color: $primary;
}

#balanceInBtn {
  color: $text-color;
  opacity: 1;
}



#category-nav {
  li button:hover {
    color: var(--bs-nav-pills-link-active-color); //#fff or $text-color;
    background-color: var(--bs-nav-pills-link-active-bg); //$primary
  }
  ul.nav {
    width: fit-content;
    padding: 0.375rem;
  }

}

//opacity overlay on provider or category logo in banner
.category-icon-overlay {
  max-width: 15%;
  opacity: 75%;
  img {
    height: 100%;
  }
}

.provider-icon-overlay {
  max-width: 20%;
  opacity: 20%;
  img {
    height: 100%;
  }
}



.searchbar {
  border-radius: 50rem !important;
  border: 1px solid mix($bg-color, #fff, 80%) !important;

  &:hover, &:focus {
    //box-shadow: 0 0.125rem 0.125rem -0.125rem rgba($bg-color, 0.20), 0 0.25rem 0.75rem rgba($bg-color, 0.20);
    border: 1px solid mix($secondary, #fff, 40%) !important;
  }

  span {
    border:0;
    background-color: transparent;
  }

  .form-control {
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }

  input:focus {
    box-shadow: none !important;
    background-color: transparent;
    border-radius: 0;
  }
}

//provider selector for filtering by provider
.provider-selector {
  .btn {
    border: 1px solid transparent;
    background-color: mix($bg-color, #fff, 80%);
    &:hover {
      border-color: var(--bs-btn-active-border-color);
      background-color: var(--bs-btn-active-border-color) !important;
      //background-color: mix($bg-color, #fff, 80%);
    }

  }
  //to remove hover border on tap devices
  @media (hover: none) {
    .btn:hover {
      border: inherit;
      background-color: mix($bg-color, #fff, 80%);
    }
    .btn-check:not(:checked) + .btn {
      border: inherit;
      background-color: mix($bg-color, #fff, 80%) !important;
    }
  }
}

.badge {
  color: $text-color !important;

  &.link-badge {
    color: mix($text-color, #000, 80%) !important;
    background-color: mix($bg-color, #fff, 80%);
    &:hover {
      color: $text-color !important;
    }
  }
}

.back-link {
  text-decoration: none;
  cursor: pointer;

  svg {
      width: 1.5rem;
      fill: $text-color;
      opacity:0.5;
      &:hover {
        opacity: 1;
      }
    }
}

//password toggle
.invalid-toggle-btn {
  margin-right: 1.5rem;
  top: 1.2rem !important;
}
.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  line-height: 1;
  cursor: pointer;

  .password-toggle-check {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1rem;
    opacity: 0;
  }

  svg {
    //eye-off opacity:0.5, eye-on opacity:1
    path {
      fill: $text-color;
      &:hover {
        fill: $primary;
      }
    }
  }
}



footer {
  .emblem {
    opacity: 0.5;
    filter: grayscale(1);

    &:hover {
      filter: none;
      opacity: 1;
    }
  }

  a {
    color: rgba($text-color, var(--bs-link-opacity, 1));
  }
}
//nice linear gradient border on top and bottom
.banner {
  border-image: linear-gradient(90deg,$bg-color,$secondary,$bg-color) 0 0 1 0;
  border-style: solid;
  border-width: 1px;
}


/* scrollbar-width and color */
//For Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: mix($bg-color, #fff, 80%) transparent;
}
//For Chrome, Edge, and Safari
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  //background: mix($bg-color, #000, 80%);
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: mix($bg-color, #fff, 80%);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: darken(mix($bg-color, #fff, 80%), 10%);
}




